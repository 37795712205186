<template>
  <div id="app">
    <b-navbar type="is-primary">
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          dkmwm | Gewohnheitsgenerator
        </b-navbar-item>
      </template>
      <template #end>
        <b-navbar-item tag="div">
          <div class="buttons">
            <a class="button is-primary" @click="isAboutModalActive = true">Wie funktioniert das?</a>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
    <section>
      <img src="@/assets/logo.png" class="logo" alt="e.fobi.de e. K." />
      <div class="columns">
        <div
          class="column behaviors is-half has-text-right pt-6 pr-6 pb-6 pl-0"
        >
          <div>
            <p class="heading">Ankermomente</p>
            <p class="title">Nachdem ich...</p>
            <div class="container my-6">
              <b-taglist class="categories">
                <div class="scroll-container">
                  <b-tag :class="{
                      'has-background-secondary': idx === selectedBehaviorCategory
                    }" v-for="(category, idx) in behaviorCategories" :key="idx"><span class="clickable" @click="selectBehaviorCategory(idx)">{{category.title}}</span></b-tag>
                </div>
              </b-taglist>
              <b-carousel-list
                v-if="behaviors"
                v-model="behaviorsCarousel"
                :data="behaviors"
                :breakpoints="carouselBreakpoints"
                :items-to-show="itemsToShow"
              >
                <template #item="list">
                  <div
                    :class="{
                      card: true,
                      'has-background-secondary':
                        list.index === selectedBehavior,
                    }"
                    @click="selectedBehavior = list.index"
                  >
                    <div class="card-content">
                      <div class="content">
                        <p class="title is-6">{{ list.title }}</p>
                        <!--<p class="subtitle is-7">@johnsmith</p>-->
                      </div>
                    </div>
                  </div>
                </template>
              </b-carousel-list>
            </div>
            <div class="row controls has-text-right">
              <b-button
                type="is-primary"
                icon-left="plus-box"
                @click="isAddBehaviorModalActive = true"
                >Anker hinzufügen</b-button
              >
            </div>
          </div>
        </div>
        <div
          class="column habits is-half has-text-left pt-6 pl-6 pb-6 pr-0 has-background-light"
        >
          <div>
            <p class="heading">Gewohnheiten</p>
            <p class="title">Werde ich...</p>
            <div class="container my-6">
              <b-taglist class="categories">
                <div class="scroll-container">
                  <b-tag :class="{
                      'has-background-secondary': idx === selectedHabitCategory
                    }" v-for="(category, idx) in habitCategories" :key="idx"><span class="clickable" @click="selectHabitCategory(idx)">{{category.title}}</span></b-tag>
                </div>
              </b-taglist>
              <b-carousel-list
                v-if="habits"
                v-model="habitsCarousel"
                :data="habits"
                :breakpoints="carouselBreakpoints"
                :items-to-show="itemsToShow"
              >
                <template #item="list">
                  <div
                    :class="{
                      card: true,
                      'has-background-secondary': list.index === selectedHabit,
                    }"
                    @click="selectedHabit = list.index"
                  >
                    <div class="card-content">
                      <div class="content">
                        <p class="title is-6">{{ list.title }}</p>
                        <!--<p class="subtitle is-7">@johnsmith</p>-->
                      </div>
                    </div>
                  </div>
                </template>
              </b-carousel-list>
            </div>
            <div class="row controls has-text-left">
              <b-button
                type="is-primary"
                icon-left="plus-box"
                @click="isAddHabitModalActive = true"
                >Gewohnheit hinzufügen</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="has-text-centered m-6">
      <b-button
        class="submit-button"
        size="is-large"
        type="is-secondary"
        icon-left="weight-lifter"
        @click="isRecipeModalActive = true"
        >Gewohnheit antrainieren</b-button
      >
    </section>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
    <footer class="footer">
      <div class="content has-text-centered">
        <p>
          Der <strong>dkmwm | Gewohnheitsgenerator</strong> ist ein Service mit <b-icon icon="heart" size="is-small" type="is-danger"></b-icon> von
          <a href="https://e-fobi.de">e-fobi.de</a>
        </p>
      </div>
    </footer>
    <b-modal v-model="isAddBehaviorModalActive" v-if="behaviors" :width="640" scroll="keep">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Anker hinzufügen</p>
          <button
            type="button"
            class="delete"
            @click="isAddBehaviorModalActive = false"
          ></button>
        </header>
        <section class="modal-card-body">
          <b-field label="Ankermoment">
            <b-input
              maxlength="200"
              type="textarea"
              v-model="customBehavior"
            ></b-input>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <button
            type="button"
            class="button is-secondary"
            @click="
              behaviors.push({ title: customBehavior });
              selectedBehavior = behaviors.length - 1;
              behaviorsCarousel = behaviors.length - 1;
              isAddBehaviorModalActive = false;
            "
          >
            <!----><span>Hinzufügen</span
            ><!---->
          </button>
        </footer>
      </div>
    </b-modal>
    <b-modal v-model="isAddHabitModalActive" v-if="habits" :width="640" scroll="keep">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Gewohnheit hinzufügen</p>
          <button
            type="button"
            class="delete"
            @click="isAddHabitModalActive = false"
          ></button>
        </header>
        <section class="modal-card-body">
          <b-field label="Gewohnheit">
            <b-input
              maxlength="200"
              type="textarea"
              v-model="customHabit"
            ></b-input>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <button
            type="button"
            class="button is-secondary"
            @click="
              habits.push({ title: customHabit });
              selectedHabit = habits.length - 1;
              habitsCarousel = habits.length - 1;
              isAddHabitModalActive = false;
            "
          >
            <!----><span>Hinzufügen</span
            ><!---->
          </button>
        </footer>
      </div>
    </b-modal>
    <b-modal v-model="isRecipeModalActive" :width="640" scroll="keep">
      <form ref="form" @submit.prevent="onSubmit">
        <vue-recaptcha ref="invisibleRecaptcha" sitekey="6LfpyEMaAAAAAIFxutwc3AY5Scab2rqz_Rij4kOk" @verify="onVerify" @expired="onExpired" size="invisible" :loadRecaptchaScript="true"></vue-recaptcha>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Neue Gewohnheit antrainieren</p>
            <button
              type="button"
              class="delete"
              @click="isRecipeModalActive = false"
            ></button>
          </header>
          <section class="modal-card-body">
            <div class="card mb-6">
              <div class="card-content">
                <p class="title recipe has-text-grey-light" v-if="behaviors && habits">
                  Nachdem ich<br /><span class="has-text-black">{{
                    behaviors[selectedBehavior].title
                  }}</span
                  >,<br />
                  werde ich<br /><span class="has-text-black">{{
                    habits[selectedHabit].title
                  }}</span
                  >.
                </p>
              </div>
            </div>
            <p class="mb-6">
              Das ist Deine neue Gewohnheit. Fang einfach an damit und trage bitte hier unten jetzt noch Deine Email Adresse ein. Du bekommst dann 5 Tage lang praktische Tipps und Tricks, die Dir helfen werden erfolgreich zu werden mit Deiner neuen Gewohnheit. Völlig kostenlos. Zeitbedarf pro Tag: Maximal 5 Minuten. Versprochen.
            </p>
            <b-field label="E-Mail-Adresse">
              <b-input type="email" v-model="email" required></b-input>
            </b-field>
            <b-field>
              <b-checkbox required
                >Hiermit bestätige ich, dass meine E-Mail-Adresse zum o.g. Zweck
                verwendet werden darf.</b-checkbox
              >
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-secondary" type="submit">
              <span>Absenden</span>
            </button>
          </footer>
        </div>
      </form>
    </b-modal>
    <b-modal v-model="isSuccessModalActive" :width="640" scroll="keep">
      <div class="modal-card">
        <section class="modal-card-body has-text-centered">
          <b-icon
            class="my-4"
            icon="checkbox-marked-circle"
            size="is-large"
            type="is-success">
          </b-icon>
          <p>
            Danke für Dein Interesse! Du erhältst in Kürze eine E-Mail mit einem Link zur Bestätigung deiner Teilnahme.
          </p>
        </section>
      </div>
    </b-modal>
    <b-modal v-model="isAboutModalActive" :width="640" scroll="keep">
      <div class="modal-card">
       <header class="modal-card-head">
            <p class="modal-card-title">Wie funktioniert das?</p>
            <button
              type="button"
              class="delete"
              @click="isAboutModalActive = false"
            ></button>
        </header>
        <section class="modal-card-body has-text-centered">
          <p>
            Mit dem dkmwm | Gewohnheitsgenerator erstellst Du ganz einfach ein Rezept für eine neue Gewohnheit. Beginne ruhig mit einem unserer Vorschläge, oder generiere Dir Dein individuelles Rezept. Drücke dann den orangenen Button und wir zeigen Dir kostenlos, wie einfach Du das in Dein Leben integrierst.
          </p>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>

import axios from 'axios';
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: "App",
  components: { VueRecaptcha },
  data() {
    return {

      isLoading: false,

      recaptcha: null,

      habitsCarousel: 0,
      behaviorsCarousel: 0,

      selectedBehavior: window.innerWidth > 1407 ? 1 : 0,
      selectedHabit: window.innerWidth > 1407 ? 1 : 0,
      selectedBehaviorCategory: 0,
      selectedHabitCategory: 0,

      isAddBehaviorModalActive: false,
      isAddHabitModalActive: false,
      isRecipeModalActive: false,
      isSuccessModalActive: false,
      isAboutModalActive: false,

      recipeModalError: null,

      customBehavior: "",
      customHabit: "",

      itemsToShow: 3.5,

      email: "",

      carouselBreakpoints: {
        0: {
          itemsToShow: 1,
        },
        481: {
          itemsToShow: 1,
        },
        769: {
          itemsToShow: 1.5,
        },
        1408: {
          itemsToShow: 2.5,
        },
      },

      behaviors: null,
      behaviorCategories: [
         { title: "Aktuelle" }
      ],
      habits: null,
      habitCategories: [
         { title: "Aktuelle" }
      ],

    };
  },
  mounted() {
    var that = this;
    axios.get("/api/records/habits?order=created_at,desc&size=8").then((res) => {
      console.log(res.data.records);
      that.habits = res.data.records;
    });
    axios.get("/api/records/behaviors?order=created_at,desc&size=8").then((res) => {
      console.log(res.data.records);
      that.behaviors = res.data.records;
    });
    axios.get("/api/records/habit_categories").then((res) => {
      console.log(res.data.records);
      that.habitCategories = that.habitCategories.concat(res.data.records);
    });
    axios.get("/api/records/behavior_categories").then((res) => {
      console.log(res.data.records);
      that.behaviorCategories = that.behaviorCategories.concat(res.data.records);
    });
  },
  methods: {
    selectHabitCategory: function(idx){
      var that = this,
        url;
      this.isLoading = true;
      if( idx ) {
        url = "/api/records/habits?filter=category,eq," + idx + "&size=8";
      }
      else {
        url = "/api/records/habits?order=created_at,desc&size=8";
      }
      axios.get(url).then(function(res){
        that.habitsCarousel = 0;
        that.selectedHabit = window.innerWidth > 1407 && res.data.records.length > 1 ? 1 : 0;
        that.habits = res.data.records;
        that.selectedHabitCategory = idx;
        that.isLoading = false;
      });
    },
    selectBehaviorCategory: function(idx){
      var that = this,
        url;
      this.isLoading = true;
      if( idx ) {
        url = "/api/records/behaviors?filter=category,eq," + idx + "&size=8";
      }
      else {
        url = "/api/records/behaviors?order=created_at,desc&size=8";
      }
      axios.get(url).then(function(res){
        that.behaviorsCarousel = 0;
        that.selectedBehavior = window.innerWidth > 1407 && res.data.records.length > 1 ? 1 : 0;
        that.behaviors = res.data.records;
        that.selectedBehaviorCategory = idx;
        that.isLoading = false;
      });
      
    },
    onSubmit: function () {
      this.$refs.invisibleRecaptcha.execute()
    },
    onVerify: function (response) {

      console.log('on verify');
      
      var that = this,
        recipe = { email: this.email, token: response, behavior: null, habit: null };

      if( this.behaviors[this.selectedBehavior] && this.behaviors[this.selectedBehavior]._id ){
        recipe['behavior'] = this.behaviors[this.selectedBehavior]._id
      }
      else {
        recipe['behavior'] = this.customBehavior;
      }
      if( this.habits[this.selectedHabit] && this.habits[this.selectedHabit]._id ){
        recipe['habit'] = this.habits[this.selectedHabit]._id
      }
      else {
        recipe['habit'] = this.customHabit;
      }

      console.log(recipe);

      axios.post('/api/records/recipes', recipe).then(res => {
        console.log(res);
        that.isRecipeModalActive = false;
        that.recipeModalError = null;
        that.email = "";
        that.isSuccessModalActive = true;
      }).catch(err => {
        console.log(err);
        that.recipeModalError = "Fehler aufgetreten. Versuchen Sie es erneut!";
      });

    },
    onExpired: function () {
      console.log('Expired')
    },
    resetRecaptcha () {
      this.$refs.recaptcha.reset()
    }
  }
};
</script>

<style lang="scss">

@import "~@mdi/font/css/materialdesignicons.css";

// Import Bulma's core
@import "~bulma/sass/utilities/_all";

$mobile: 481px;

// Set your colors
$primary: #686868;
$primary-light: findLightColor($primary);
$primary-dark: findDarkColor($primary);
$primary-invert: findColorInvert($primary);
$twitter: #4099ff;
$twitter-invert: findColorInvert($twitter);
$secondary: #F59641;
$secondary-light: findLightColor($secondary);
$secondary-dark: findDarkColor($secondary);
$secondary-invert: findColorInvert($secondary);

// Lists and maps
$custom-colors: null !default;
$custom-shades: null !default;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: mergeColorMaps(
  (
    "white": (
      $white,
      $black,
    ),
    "black": (
      $black,
      $white,
    ),
    "light": (
      $light,
      $light-invert,
    ),
    "dark": (
      $dark,
      $dark-invert,
    ),
    "primary": (
      $primary,
      $primary-invert,
      $primary-light,
      $primary-dark,
    ),
    "secondary": (
      $secondary,
      $secondary-invert,
      $secondary-light,
      $secondary-dark,
    ),
    "link": (
      $link,
      $link-invert,
      $link-light,
      $link-dark,
    ),
    "info": (
      $info,
      $info-invert,
      $info-light,
      $info-dark,
    ),
    "success": (
      $success,
      $success-invert,
      $success-light,
      $success-dark,
    ),
    "warning": (
      $warning,
      $warning-invert,
      $warning-light,
      $warning-dark,
    ),
    "danger": (
      $danger,
      $danger-invert,
      $danger-light,
      $danger-dark,
    ),
  ),
  $custom-colors
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

section {
  position: relative
}

img.logo {
  height: 10rem;
  top: 2rem;  
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  max-height: none !important;
  padding: 1rem 0;
  max-width: calc(100% - 2rem);
  height: auto;
  width: 360px
}

.columns {
  margin-left: 0;
  margin-right: 0
}

.columns .column {
  padding-top: 14rem !important
}

.carousel-list {
  box-shadow: none !important;
}
.carousel-list .carousel-slide {
  padding: 1rem;
}
.carousel-list .carousel-slide .card {
  height: 100%;
  min-height: 102px
}
.column.behaviors .carousel-list {
  border-right: 1px solid #eee;
}
.column.habits .carousel-list {
  border-left: 1px solid #e1e1e1;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.categories::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.categories {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  overflow-x: auto;
}

.categories .scroll-container {
  white-space: nowrap;
  padding-right: 1rem
} 

.behaviors .categories .scroll-container {
  padding-left: 1rem;
  padding-right: 0
}

.categories .tag .clickable {
  cursor: pointer
}

.habits .categories .tag {
  background-color: #fff
}

@include until($tablet) {
  .column {
    color: #f00;
  }
  .column.behaviors {
    padding-left: 3rem !important;
  }
  .column.habits {
    padding-top: 3rem !important;
    padding-right: 3rem !important
  }
  .submit-button {
    font-size: 1rem !important;
    i.mdi:before {
      font-size: 28px !important
    }
  }
  .row.controls {
    text-align: center !important
  }
}
</style>
